<template>
<div>
  <v-col cols="12" sm="6" md="5" lg="4" class="mx-auto mt-5">
    <v-card>

      <v-col cols="12">
        <v-card-title> <v-icon class="mr-2">mdi-login</v-icon> Se connecter</v-card-title> 
        <!-- <v-card-subtitle></v-card-subtitle> -->
        <v-form @submit.prevent="login">

          <v-text-field filled v-model="email" id="email" label="E-mail"></v-text-field>
          <v-text-field filled v-model="password" id="password" label="Mot de passe"
                        :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showpwd ? 'text' : 'password'"
                        @click:append="showpwd = !showpwd"
          ></v-text-field>
          <v-alert v-if="$store.state.auth.login.error != null" 
                   dense dark color="red" class="mt-3 text-center">
                   <v-icon small>mdi-alert</v-icon>
            {{ $store.state.auth.login.error|json }}
          </v-alert>

          <v-btn class="white--text" color="secondary" @click="login" block>
            <v-icon>mdi-login</v-icon> Se connecter
          </v-btn>

        </v-form>
      </v-col>

    </v-card>
  </v-col>

  <v-col cols="12" sm="8" md="6" class="mx-auto text-center">
    Vous n'êtes pas inscrit ?<br>
    <v-btn class="mt-3" outlined color="secondary" to="/register">
      <v-icon>mdi-account-plus</v-icon> Inscrivez-vous
    </v-btn>
  </v-col>

</div>
</template>

<script>

//import router from '../router/router'

export default {
  name: 'login',
  components: {
  },
  data: () => ({
    email: '',
    password: '',
    resColor: '',
    resMsg: 'sfsdfsdf',
    showpwd: false
  }),
  methods: {
    async login(){
      try{
        this.$store.dispatch('app/incStat', '/login')
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })        
      } catch(err) {
          console.log('Cannot login user. Error:', err)       
      }

    }
  }
}
</script>
